import React, { useState } from "react"
// import qs from "qs"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import Section from "../../../../components/Section"
import Image from "../../../../components/actions/Image"
import Input from "../../../../components/actions/Input"
import { useForm } from "react-hook-form"

import axios from "axios"
import { navigate } from "gatsby-link"

export default function Request(props) {
  // const value = qs.parse(
  //   typeof window !== "undefined"
  //     ? window.location.search.substring(1)
  //     : `name=test`
  // )
  // console.log(value.name)

  // const [form, setForm] = useState({
  //   name: "",
  //   passengers: "",
  //   date: "",
  // })

  return (
    <Layout>
      <Seo title={"Booking a personalised "} />
      <Section>
        <Image
          src={"undefined"}
          title="Get your personalised Ibiza villa recommendations"
        />
        <Forms value="" />
      </Section>
    </Layout>
  )
}

const Forms = ({ value }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    // defaultValues: {
    //   start: addDays(new Date(), 14),
    //   end: addDays(new Date(), 21),
    //   adults: 4,
    //   children: 2,
    // },
  })

  const onSubmit = async data => {
    const url =
      "https://sxvls3nwxi.execute-api.eu-west-1.amazonaws.com/master/actions/personalised-villas"

    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    navigate("/actions/landing/personalised-villas/complete")
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow-xl p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 xl:gap-8">
          <h4 className="col-span-1 lg:col-span-3 text-[24px] font-serif">
            Your Details
          </h4>

          <Input
            rule="required"
            label="Your Email"
            name="email"
            type="email"
            register={register}
            field="email"
          />
          <Input
            rule="required"
            label="Your Phone"
            name="phone"
            type="phone"
            className="col-start-1 row-start-4 lg:row-start-3"
            register={register}
            field="phone"
          />
          <Input
            rule="required"
            label="Your Country"
            name="country"
            type="country"
            className="row-start-5 lg:row-start-3"
            register={register}
            field="country"
          />
        </div>
        <div className="shadow-xl p-4  lg:p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 xl:gap-8 mt-8">
          <h2 className="col-span-1 lg:col-span-3 text-[24px] font-serif">
            Your Requirements
          </h2>
          <Input
            rule="required"
            label="Check In"
            name="Check-in"
            type="date"
            register={register}
            field="from"
            // value={value.checkIn}
            // readOnly
          />
          <Input
            rule="required"
            label="Bedrooms"
            name="Bedroom"
            type="number"
            register={register}
            field="bedrooms"
          />
          <Input
            rule="required"
            label="Adults"
            name="Adults"
            type="number"
            register={register}
            field="adults"
            // value={value.adults}
            // readOnly
          />
          <Input
            rule="required"
            label="Check Out"
            name="Check-out"
            type="date"
            register={register}
            field="to"
            // value={value.checkOut}
            // readOnly
          />
          <Input
            rule="required"
            label="Budget"
            name="Budget"
            type="number"
            register={register}
            field="budget"
          />
          <Input
            rule="required"
            label="Children"
            name="Children"
            type="number"
            register={register}
            field="children"
            // value={value.children}
            // readOnly
          />
          <Input
            textarea
            rule="required"
            label="Describe your perfect holiday villa"
            name="notes"
            className="col-span-1 lg:col-span-3"
            register={register}
            field="notes"
          />
        </div>

        <div className="mt-8 mr-4 flex justify-end items-center">
          <button
            type="submit"
            className="px-12 py-4 outline-none focus:outline-none text-sm font-bold text-white bg-black"
          >
            Book Now
          </button>
        </div>
      </form>
    </div>
  )
}
